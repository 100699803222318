'use strict';

var atrack;

/**
 * Initialize the GDDL analytics tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

/**
 * Creates the event data object and sends it to the analytics tracker
 * @param {boolean} returnDefaultObject Should we send an empty result object
 * @param {*} name Name of the event
 * @param {*} stepNumber Number of the event, default is an empty string
 * @param {*} errorType Error type (in the case of an error)
 * @param {*} errorMessage Error message (in the case of an error)
 */
function sendRegistrationEvent(returnDefaultObject, name, stepNumber = '', errorType = '', errorMessage = '') {
    let resultObject = {
        account_type: '',
        rc_member: '',
        rc_total: '',
        optin_promo: '',
        method: ''
    };

    const $trackingTag = $('meta[itemprom="analytics"].js-account-tracking');
    const objData = $trackingTag.length && $trackingTag.attr('content');
    const accountTrackingObj = objData.length && objData !== 'undefined' ? JSON.parse(objData) : null;
    if (!returnDefaultObject && accountTrackingObj) {
        resultObject = Object.assign({}, accountTrackingObj);
        resultObject.method = sessionStorage.getItem('gddl_registration_social') || 'email';
    }

    const eventData = {
        event: name,
        info: {
            type: 'form',
            name: 'registration',
            placement: sessionStorage.getItem('gddl_registration_source') || 'direct',
            step_number: stepNumber,
            step_name: 'account creation',
            result: resultObject,
            error_type: errorType,
            error_message: errorMessage
        }
    };

    atrack.trackEvent(eventData);
}

/**
 * Initialize the social registration tracker
 */
function initSocialCheck() {
    $(document).on('click', '.gddl-register-google,.gddl-register-facebook', function () {
        sessionStorage.setItem('gddl_registration_social', $(this).is('.gddl-register-google') ? 'google' : 'facebook');
    });
}

// Order-CreateAccount?ID=00000401 possible end page with redirect to Account-Show?registration=submitted

/**
 * Initialize the source tracker
 */
function initSourceCheck() {
    $(document).on('click', '.gddl-registration-button', function () {
        const $this = $(this);

        if ($this.is('.login-flyout *')) {
            sessionStorage.setItem('gddl_registration_source', 'pop-up');
        } else if ($this.is('.checkout-login *')) {
            sessionStorage.setItem('gddl_registration_source', 'checkout');
        } else if ($this.is('.page-login *')) {
            sessionStorage.setItem('gddl_registration_source', 'login page');
        } else if ($this.is('.checkout-registration *')) {
            sessionStorage.setItem('gddl_registration_source', 'after guest checkout');
        }

        sendRegistrationEvent(true, 'tool-start');
    });
}

/**
 * Initialize the start step
 */
function initStartStep() {
    const $page = $('.page');
    const action = $page.data('action');
    const queryString = $page.data('querystring');

    if (action && (action === 'Account-Register' || (action === 'Checkout-BeginGuest' && (queryString && queryString === 'registerUser=true')))) {
        sendRegistrationEvent(true, 'tool-step', 1);
    }
}

/**
 * Initialize the confirm step
 */
function initConfirmStep() {
    const queryString = $('.page').data('querystring');
    if (queryString && queryString === 'registration=submitted') {
        sendRegistrationEvent(false, 'tool-laststep', 2);
    }
}

/**
 * Initialize the email exists event
 */
function initEmailExists() {
    $('body').on('register:email-exists', function () {
        sendRegistrationEvent(false, 'tool-error', 1, 'user', 'E-mail bestaat al');
    });
}

const init = () => {
    initAnalyticsTracker();
    initSocialCheck();
    initSourceCheck();
    initStartStep();
    initConfirmStep();
    initEmailExists();
};

$(() => {
    init();
});
